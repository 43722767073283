import clsx from 'clsx';
import { HeaderUserMenu } from '../../../partials';
import { useLayout } from '../../core';
import { useUserInfo } from '../../../../hooks/useUserInfo';

const itemClass = 'ms-1 ms-lg-3';
const userAvatarClass = 'symbol-35px symbol-md-0px';

const Navbar = () => {
  const { config } = useLayout();
  const userInfo = useUserInfo();

  return (
    <>
      <div>
        <span
          style={{
            color: '#212B36',
            fontSize: '14px',
            whiteSpace: 'nowrap',
            display: 'block',
            textAlign: 'right',
            fontWeight: '500',
          }}
        >
          {userInfo?.Roles != null ? userInfo?.Roles[0].RoleName : ''}
        </span>
      </div>
      <div className="app-navbar flex-shrink-0">
        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
          >
            <div style={avatarInitials}>
              <span style={{ color: '#fff' }}>{userInfo?.FirstName?.[0]}</span>
              <span style={{ color: '#fff' }}>{userInfo?.LastName?.[0]}</span>
            </div>
          </div>
          <HeaderUserMenu />
        </div>
      </div>
    </>
  );
};

export const avatarInitials = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '45px',
  height: '45px',
  borderRadius: '50%',
  backgroundColor: '#555AAC',
  fontSize: '18px',
};

export { Navbar };
