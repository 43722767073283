import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  title: 'Provider',
  notificationCount:0
};

// Create a slice
const dataSlice = createSlice({
  name: 'header', // Slice name
  initialState,
  reducers: {
    setData(state, action) {
      state.title = action.payload;
    },
    setNotificationCount(state, action){
      state.notificationCount = action.payload
    }
  },
});

// Export actions
export const { setData,setNotificationCount } = dataSlice.actions;

// Export reducer
export default dataSlice.reducer;
