const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
export const userToken = localStorage.getItem('userToken') || '';

// configure header's Content-Type as JSON
export const config = {
  headers: {
    ContentType: 'application/json',
    accept: 'application/json',
    Authorization: userToken ? `Bearer ${userToken}` : '',
  },
};

export const APP_URLs = {
  mediaUpload: `${BASE_URL}/media/upload`,
  viewDocBaseURL: 'https://thrive-ce.s3.ap-south-1.amazonaws.com',
  authEndPoints: {
    login: `${BASE_URL}/token/requestToken`,
    logout: `${BASE_URL}/admin/logout`,
  },
  appUser: {
    appProviderList: `${BASE_URL}/provider/getProviders`,
    editClinician: `${BASE_URL}/provider/updateProvider`,
    companyDocument: `${BASE_URL}/companyDocument/getCompanyDocuments`,
    documentTypes: `${BASE_URL}/documentType/getDocumentTypeDetails`,
    suspendAdmin: `${BASE_URL}/admin/suspend-user`,
    adminList: `${BASE_URL}/admin/adminList`,
    updateProviderStatus: `${BASE_URL}/provider/updateProfileStatus`,
    updateProviderProfile: `${BASE_URL}/provider/updateProviderProfile`,
    addUpdateCompanyProviderDocuments: `${BASE_URL}/provider/addUpdateCompanyProviders`,
    updateProviderSkill: `${BASE_URL}/provider/updateProviderSkillStatus`,
    updateProviderDocument: `${BASE_URL}/provider/updateProviderDocumentStatus`,
  },
  assignments: {
    getAssignment: `${BASE_URL}/assignment/getAssignments`,
    addAssignment: `${BASE_URL}/assignment/createAssignment`,
    updateAssignment: `${BASE_URL}/assignment/updateAssignment`,
    getAssignmentById: `${BASE_URL}/assignment/getAssignmentById`,
    getAssignmentSchedulesByAssignmentId: `${BASE_URL}/assignmentSchedule/getAssignmentSchedulesByAssignmentIdAndStatus`,
    deleteAssignment: `${BASE_URL}/assignment/deleteAssignment`,
    dropAssignment: `${BASE_URL}/assignment/updateAssignmentStatus`,
  },
  staff: {
    getStaff: `${BASE_URL}/staff/getStaffs`,
    addStaff: `${BASE_URL}/staff/postStaff`,
    editStaff: `${BASE_URL}/staff/putStaff`,
  },
  clockOut:{
    clincianClockOut: `${BASE_URL}/assignmentSchedule/clockout`
  },
  clinianInpogressShift: {
    InprogressShiftCheck: `${BASE_URL}/assignmentSchedule/getInProgrssAssignmentScheduleByProviderId`
  },
  patinet: {
    patientdetail: `${BASE_URL}/patientOtherInformation/getPatientOtherInformations`,
  },
  offers: {
    offersCommon: `${BASE_URL}/offer`,
  },
  banners: {
    bannerComnonApi: `${BASE_URL}/banner`,
  },
  booking: {
    genericSlotBookingAPI: `${BASE_URL}/slotBooking`,
  },
  subscriptionInfo: {
    genericSubscriptionInfoAPI: `${BASE_URL}/subscriptionInfo`,
  },
  companydetails: {
    companylist: `${BASE_URL}/company/getCompanyDetails`,
    companyName: `${BASE_URL}/company/getCompanyDetails`,
    updateCompanyStatus: `${BASE_URL}/company/updateCompanyStatus`,
    companyaddress: `${BASE_URL}/company/getCompanyAddressesByCompanyId`,
    companies: `${BASE_URL}/company/getCompanies`,
  },
  companydetailsbyid: {
    companylist: `${BASE_URL}/company/getCompanyDetailsById`,
    updateCompany: `${BASE_URL}/company/updateCompany`,
    deactiveCompany: `${BASE_URL}/company/activeDeactiveCompany`,
  },
  coupon: {
    genericCouponAPI: `${BASE_URL}/coupon`,
  },
  changeUserPassword: {
    genericAPI: `${BASE_URL}/user/change-password`,
  },
  forgotpassword: {
    sendotpforverification: `${BASE_URL}/token/sendOTPForForgotPasswordVerification`,
  },
  refreshToken: {
    refresh: `${BASE_URL}/token/refreshToken`,
  },
  otpverification: {
    verifyotp: `${BASE_URL}/token/forgotPasswordOTPVerification`,
  },
  passwordchange: {
    changedpassword: `${BASE_URL}/token/resetPassword`,
    ChangePassword: `${BASE_URL}/token/changePassword`,
  },
  company: {
    CreateCompany: `${BASE_URL}/company/createCompany`,
  },
  membershipDescription: {
    genericMembershipDescription: `${BASE_URL}/static/membership-description`,
  },
  squezzMe: {
    getSquezzMeList: `${BASE_URL}/slotRequest`,
    updateRequestStatus: `${BASE_URL}/slotRequest/updateStatus`,
  },
  interest: {
    genericInterestAPI: `${BASE_URL}/interest`,
  },
  apparel: {
    genericApparelAPI: `${BASE_URL}/apparel`,
  },
  dashboard: {
    getDashboard: `${BASE_URL}/dashboard/analytics`,
  },
  provider: {
    providerAPI: `${BASE_URL}/provider/registerProvider`,
    searchprovider: `${BASE_URL}/provider/searchProviders`,
    editprovider: `${BASE_URL}/provider/getProviderById`,
    viewInvitee: `${BASE_URL}/provider/viewInvitee`,
    updateClinicianStatus: `${BASE_URL}/provider/addUpdateProviderPrograms`,
    updateProviderDNRStatus: `${BASE_URL}/provider/updateProviderDNRStatus`,
    updateProviderDNRStatusToTerminate: `${BASE_URL}/provider/terminateUnterminateProvider`,
    updateProviderDNRStatusToSuspend: `${BASE_URL}/provider/suspendUnsuspendProvider`,
    getProviderDocumentsByProviderId: `${BASE_URL}/provider/getProviderDocumentsByProviderId`,
    updateProviderReferenceStatus: `${BASE_URL}/provider/updateProviderReferenceStatus`,
  },
  commonEndPoints: {
    getCountries: `${BASE_URL}/country/getCountries`,
    getStates: `${BASE_URL}/state/getStates`,
    getCities: `${BASE_URL}/city/getCities`,
  },
  thriveEndpoints: {
    thriveCommonAPI: `${BASE_URL}/provider/registerProvider`,
  },
  documents: {
    documentAPI: `${BASE_URL}/companyDocument/createCompanyDocument`,
    documentTypeAPI: `${BASE_URL}/documentType/createOnBoardDocumentType`,
    editdocumentAPI: `${BASE_URL}/companyDocument/updateCompanyDocument`,
    editdocumentTypeAPI: `${BASE_URL}/documentType/updateOnBoardDocumentType`,
    deletedocumentAPI: `${BASE_URL}/companyDocument/deleteCompanyDocument`,
    deletedocumentTypeAPI: `${BASE_URL}/documentType/deleteDocumentType`,
    getClinicianDocument: `${BASE_URL}/documentType/getDocumentTypeDetails`,
  },
  skill: {
    createSkill: `${BASE_URL}/skill/createSkill`,
    editskillAPI: `${BASE_URL}/skill/updateSkill`,
    deleteSkill: `${BASE_URL}/skill/deleteSkill`,
    getSkills: `${BASE_URL}/skill/getSkills`,
    getSkillsData: `${BASE_URL}/skill/getSkillDetails`,
  },
  program: {
    createProgram: `${BASE_URL}/program/createProgram`,
    editProgram: `${BASE_URL}/program/updateProgram`,
    deleteProgram: `${BASE_URL}/program/deleteProgram`,
    getProgramDetails: `${BASE_URL}/program/getProgramDetails`,
    getPrograms: `${BASE_URL}/program/getPrograms`,
  },
  assignmentschedule: {
    getAssignmentschedule: `${BASE_URL}/assignmentSchedule/getAssignmentSchedules`,
    getAssignmentscheduleid: `${BASE_URL}/assignmentSchedule/getAssignmentSchedulesDetailsByAssignmentId`,
    getViewAssignmentschedule: `${BASE_URL}/assignmentSchedule/viewAssignmentSchedules`,
    assignmentStatus: `${BASE_URL}/assignmentSchedule/verifyAssignmentSchedule`,
    getAssignmentschedulebyassignment: `${BASE_URL}/assignmentSchedule/getAssignmentSchedulesByAssignment`,
    getAssignmentschedulebyidAPI: `${BASE_URL}/assignmentSchedule/getAssignmentScheduleById`,
    DropAssignment: `${BASE_URL}/assignmentSchedule/updateAssignmentScheduleStatus`,
  },
  nursinghome: {
    createnursinghome: `${BASE_URL}/nursingHome/createNursingHome`,
    getNursinghome: `${BASE_URL}/nursingHome/getNursingHomeDetails`,
    editnursinghomeAPI: `${BASE_URL}/nursingHome/updateNursingHome`,
    deletenursinghomeAPI: `${BASE_URL}/nursingHome/deleteNursingHome`,
    NursingHomeAddressesByCompanyId: `${BASE_URL}/nursingHome/getNursingHomes`,
    getShifts: `${BASE_URL}/shift/getShifts`,
    getShiftsDetails: `${BASE_URL}/shift/getShiftDetails`,
    createShift: `${BASE_URL}/shift/createShift`,
    editShift: `${BASE_URL}/shift/updateShift`,
    deletedShiftAPI: `${BASE_URL}/shift/deleteShift`,
  },
  city: {
    addcity: `${BASE_URL}/city/createCity`,
    getcity: `${BASE_URL}/city/getCities`,
    deletecity: `${BASE_URL}/city/deleteCity`,
    editcity: `${BASE_URL}/city/updateCity`
  },
  state: {
    addState: `${BASE_URL}/state/createState`,
    getState: `${BASE_URL}/state/getStates`,
    deleteState: `${BASE_URL}/state/deleteState`,
    editState: `${BASE_URL}/state/updateState`
  },
  staticContent: {
    genericAPIs: `${BASE_URL}/staticContent/getStaticContents`,
    postContent: `${BASE_URL}/staticContent/addUpdateStaticContent`,
  },
  notification: {
    getNotification:`${BASE_URL}/staffNotifications/getNotifications`,
    deleteNotification:`${BASE_URL}/staffNotifications/deleteNotifications`,
    getNotificationCount:`${BASE_URL}/staffNotifications/getUnreadNotificationCount`,
    readNotifications:`${BASE_URL}/staffNotifications/readNotifications`,
  },
};
