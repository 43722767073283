import { Box, Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import MuiTable from '../../../../components/table/MuiTable';
import { KTCard, KTIcon } from '../../../helpers';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DoNotDisturbOffIcon from '@mui/icons-material/DoNotDisturbOff';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import { IconButton, Popover } from '@mui/material';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import { formatDate } from '../../../../helpers/commonFunctions';
import useDebounce from '../../../../hooks/useDebounce';
import { isSuperAdmin, useUserInfo } from '../../../../hooks/useUserInfo';
import {
  getAppProviderList,
  getInProgressAssignmentScheduleByProviderId,
  getSkills,
  getSkillsData,
  updateProviderDNRStatusToSuspend,
  updateProviderDNRStatusToTerminate,
  updateProviderStatus,
} from '../api';
import ConfirmEventPopup from '../common/ConfirmEventPopup';
import ViewProvider from './View';
import UpdateClinicianStatusToDNR from './clinician-status/UpdateClinicianStatusToDNR';
import UpdateClinicianStatusToSuspend from './clinician-status/UpdateClinicianStatusToSuspend';
import UpdateClinicianStatusToTerminatePopUp from './clinician-status/UpdateClinicianStatusToTerminate';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { resetAllDocumentsValues } from '../../../../store/slice/form/document_form';
import { useDispatch } from 'react-redux';
import { ReactComponent as Edit } from '../../../assets/svg/edit.svg';
import { ReactComponent as Unblock } from '../../../assets/svg/unblock.svg';
import { ReactComponent as Setting } from '../../../assets/svg/setting.svg';
import FilterCommonPopUp from '../common/FilterCommonPopUp';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import HistoryIcon from '@mui/icons-material/History';
import ShowClinicianHistoryInPopup from '../../../../helpers/clinicianStatusHistoryPopup';

interface LocationState {
  providerdata: any;
}

const Clinician: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [openClinicianDNRPopUp, setOpenClinicianDNRPopUp] = useState(false);
  const [openClinicianTerminatePopUp, setOpenClinicianTerminatePopUp] =
    useState(false);
  const [openClinicianSuspendPopUp, setOpenClinicianSuspendPopUp] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [openClinicianUnterminatePopUp, setOpenClinicianUnterminatePopUp] =
    useState(false);
  const [openClinicianUnSuspendPopUp, setOpenClinicianUnSuspendPopUp] =
    useState(false);
    const [openInProgressShiftPopUp, setOpenInProgressShiftPopUp] =
    useState(false);
  const [showActiveDeActivePopup, setShowActiveDeActivePopup] = useState(false);
  const [isForFilter, setIsForFilter] = useState(false);
  const [showStatusModel, setshowStatusModel] = useState('');
  const [assignmentId, setAssignmentId] = useState({
    Id: ''
  });
  const [showUserId, setShowUserId] = useState('');
  const [selectedSkill, setSelectedSkill] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [showFilterPopUp, setShowFilterPopUp] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const userInfo = useUserInfo();
  
  const dispatch = useDispatch();
  const [anchorElForHistory, setAnchorElForHisory] = useState(null);
  const openRef: boolean = Boolean(anchorEl);
  const openHisory: boolean = Boolean(anchorElForHistory);
  const providerId =(location.state as LocationState)?.providerdata?.ProviderId || '';

  const HisotryId: string | undefined = openRef
    ? 'provider-history-popover'
    : undefined;

  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: '',
    companyId: 0,
    skilIds: undefined,
    profileStatusIds: undefined,
  });
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);
  let defaultQuery = {
    offset: 0,
    limit: 10,
    roleId: 3,
    search: debounceSearchValue ?? '',
    companyId: tableOptions?.companyId ?? 0,
  };
  const tableRef = useRef();

  const {
    mutateAsync: getAppProviders,
    data: appProviderListData,
    isLoading: isGettingThriveData,
  } = useMutation('golf-list', getAppProviderList);

  const { mutateAsync: updateProviderData, isLoading: isUpdateProfile } =
    useMutation('updateProviderStatus', updateProviderStatus);

  const { mutateAsync: UnterminateAPI, isLoading: isUnterminate } = useMutation(
    'clinician-status-terminate',
    updateProviderDNRStatusToTerminate
  );

  const { mutateAsync: UnsuspendAPI, isLoading: isUnsuspend } = useMutation(
    'clinician-status-suspend',
    updateProviderDNRStatusToSuspend
  );

  const {
    data: InProgressShiftData,
    mutateAsync: getInProgressShiftByProviderId,
    isLoading: isInprogressShiftloading,
  } = useMutation('Inprogress-shift-clinician', getInProgressAssignmentScheduleByProviderId);

  useEffect(() => {
    getCustomer({
      ...tableOptions,
      search: debounceSearchValue ?? '',
    });
    dispatch(resetAllDocumentsValues());
  }, [tableOptions?.companyId, debounceSearchValue]);

  useEffect(() => {
    if(isForFilter){
      getCustomer({
        ...tableOptions,
        search: debounceSearchValue ?? '',
      });
      setIsForFilter(false)
      dispatch(resetAllDocumentsValues());
    }
  }, [isForFilter, debounceSearchValue]);

  useEffect(() => {
    if(providerId){
      handleViewModalOpen()
    }
  }, [providerId]);

  const getCustomer = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
          companyId: number;
        }
      | undefined
  ) => {
    let response = await getAppProviders(query);
    if (response?.IsSuccess) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.PageDetail?.Skip,
          limit: response?.PageDetail?.Take,
          hasNextPage: response?.PageDetail?.HasNext,
          hasPrevPage: response?.PageDetail?.HasPrevious,
          totalRowCount: response?.PageDetail?.Count,
        };
      });
    }
  };

  const handleStatus = (value: any, id: any) => {
    setShowDeleteConfirmModal(true);
    setshowStatusModel(value);
    setShowUserId(id);
    setAnchorEl(null);
  };

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setShowViewModal(false);
    handleCloseActivePopup();
    setSelectedRow('');
    if (isSubmit) {
      let query = {
        offset: tableOptions?.offset ?? 10,
        limit: tableOptions?.limit ?? 10,
        roleId: 3,
        search: debounceSearchValue ?? '',
        companyId: tableOptions?.companyId ?? 0,
      };
      getCustomer(query);
    }
  };
  const handleCloseActivePopup = () => {
    setShowActiveDeActivePopup(false);
  };

  const handleViewModalOpen = () => {
    setShowViewModal(true);
    setAnchorEl(null);
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setOpenClinicianUnterminatePopUp(false);
    setOpenClinicianUnSuspendPopUp(false);
    setOpenInProgressShiftPopUp(false)
    setshowStatusModel('');
  };

  const handleCloseUpdateClinicianStatusPopup = (isSubmit: boolean) => {
    setOpenClinicianDNRPopUp(false);
    setshowStatusModel('');
    if (isSubmit) {
      getCustomer(tableOptions);
    }
  };

  const closeTerminatePopup = (isSubmit: boolean) => {
    setOpenClinicianTerminatePopUp(false);
    setshowStatusModel('');
    if (isSubmit) {
      getCustomer(tableOptions);
    }
  };

  const closeSuspendPopup = (isSubmit: boolean) => {
    setOpenClinicianSuspendPopUp(false);
    setshowStatusModel('');
    if (isSubmit) {
      getCustomer(tableOptions);
    }
  };

  const Unterminateclinician = async () => {
    let payload = {
      ProviderId: selectedRow?.Id ?? 0,
      IsTerminated: false,
    };
    const response = await UnterminateAPI(payload);
    if (response) {
      snackActions.success(response?.Message);
      handleCloseDelete();
      getCustomer(defaultQuery);
    }
  };

  const Unsuspendclinician = async () => {
    let payload = {
      ProviderId: selectedRow?.Id ?? 0,
      IsSuspended: false,
      SuspensionDays: 0,
    };
    const response = await UnsuspendAPI(payload);
    if (response) {
      snackActions.success(response?.Message);
      handleCloseDelete();
      getCustomer(defaultQuery);
    }
  };

  const updateProviderStatusAPI = async (status: any, ProviderId: any) => {
    let statusValue;
    if (status == 'approve') {
      statusValue = 2;
    } else if (status == 'block') {
      statusValue = 4;
    } else if (status == 'Reject') {
      statusValue = 3;
    } else if (status == 'unblock') {
      statusValue = 2;
    }

    let response = await updateProviderData({
      id: ProviderId,
      status: statusValue,
    });
    if (response?.IsSuccess) {
      setShowDeleteConfirmModal(false);
      snackActions.success(response?.Message);
      let query = {
        offset: tableOptions?.offset ?? 10,
        limit: tableOptions?.limit ?? 10,
        search: debounceSearchValue ?? '',
        companyId: tableOptions?.companyId ?? 0,
      };
      handleCloseDelete();
      getCustomer(query);
    } else {
      snackActions.error(response?.message);
    }
  };

  const handleFilterModalClose = (obj: any, isForClose: boolean) => {
    setShowFilterPopUp(false);
    if(!isForClose){
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          ['skilIds']: obj?.SelectedSkill,
          ['profileStatusIds']: obj?.SelectedStatus,
        };
      });
      setSelectedSkill(obj?.SelectedSkill);
      setSelectedStatus(obj?.SelectedStatus);
      setIsForFilter(true)
    }
  else{
    setIsForFilter(false)
  }
  };

  const handleCheckClinicianShiftStatus = async(action: any, Id: any) => {
    let response = await getInProgressShiftByProviderId({id: Id});
    if(response?.Result == null){
      if(action == "Terminate"){
        setOpenClinicianTerminatePopUp(true)
      }
      else if(action == "Suspend"){
        setOpenClinicianSuspendPopUp(true);
      }
        setAnchorEl(null);
    }
    else{
      setOpenInProgressShiftPopUp(true)
      setAssignmentId({Id: response?.Result?.AssignmentId})
      setAnchorEl(null)
    }
  }

  const RedirectToManageShiftScreen = () => {
    navigate('/shifts-detail', { state: { assignmentdata: assignmentId, isFrom: "Clinician" } })
  }

  const handleHistoryClick = (data: any, event: any) => {
    setSelectedRow(data);
    setAnchorElForHisory(event.currentTarget);
  };

  const handleCloseHistory = () => {
    setAnchorElForHisory(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 2.4,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) =>
        data?.row?.FirstName + ' ' + data?.row?.LastName ?? '-',
    },
    {
      field: 'Skill',
      headerName: 'Clinician Skill',
      flex: 3,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => data?.row?.SkillName ?? '-',
    },
    {
      field: 'MobileNumber',
      headerName: 'Phone Number',
      flex: 1.7,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const phoneNumber = data?.row?.MobileNumber
          ? `(+1) ${data?.row?.MobileNumber}`
          : '-';
        return <>{phoneNumber}</>;
      },
    },
    {
      field: 'CreatedOn',
      headerName: 'Date',
      flex: 2.2,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => formatDate(data?.row?.CreatedOn, 'MM/dd/yyyy'),
    },
    {
      field: 'isActive',
      headerName: 'Status',
      flex: 1.5,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const profileStatusId = data?.row?.ProfileStatusId;
        let backgroundColor = '';
        let statusTextColor = '';

        switch (profileStatusId) {
          case ProfileStatusName.Approved:
            backgroundColor = '#00786729';
            statusTextColor = '#007867';
            break;
          case ProfileStatusName.Pending:
            backgroundColor = '#F6851F29';
            statusTextColor = '#F6851F';
            break;
          case ProfileStatusName.Blocked:
            backgroundColor = '#FF563029';
            statusTextColor = '#FF5630';
            break;
          case ProfileStatusName.Rejected:
            backgroundColor = '#FF563029';
            statusTextColor = '#FF5630';
            break;
          case ProfileStatusName.Suspended:
            backgroundColor = '#FF563029';
            statusTextColor = '#FF5630';
            break;
          case ProfileStatusName.Terminated:
            backgroundColor = '#3D0008';
            statusTextColor = 'white';
            break;
          default:
            backgroundColor = '';
            break;
        }

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center', // Ensure vertical alignment of content
            }}
          >
          <div
            className="d-flex align-items-center stsusDatadesign"
            style={{
              backgroundColor: backgroundColor,
              padding: '5px 10px', // Optional: Add some padding to make it look better
              display: 'flex',
              alignItems: 'center', // Ensure status text is vertically centered
            }}
          >
            {/* Status text */}
            <span
              style={{
                color: statusTextColor,
                fontWeight: 'bold',
              }}
            >
              {data?.row.ProfileStatusName}
            </span>
          </div>

          <div
              style={{
                display: 'flex',
                alignItems: 'center',
                visibility: data?.row?.ProviderDNRHistory && data.row.ProviderDNRHistory.length > 0 ? 'visible' : 'hidden', 
                width: '24px',
                justifyContent: 'center', 
              }}
            >
          {data?.row?.ProviderDNRHistory && data.row.ProviderDNRHistory.length > 0 && (
            <div className="d-flex align-items-start"  style={{marginLeft:'20px'}} onClick={e => handleHistoryClick(data?.row, e)}>
            <i className="bi bi-info-circle-fill fs-4 text-danger me-3"></i>
          </div>
          )}
  </div>
        </div>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.8,
      minWidth: 100,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        let status: any;
        if (data?.row?.ProfileStatusId == 2) {
          status = 'block';
        } else if (data?.row?.ProfileStatusId == 4) {
          status = 'approve';
        }
        const handleClick = (value: any, e: any) => {
          setSelectedRow(value);
          setAnchorEl(e.currentTarget);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };

        return (
          <div className="d-flex align-items-center">
            <IconButton onClick={e => handleClick(data?.row, e)}>
              <Setting />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                p: 1,
                '& .MuiPaper-root': {
                  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.03)',
                },
              }}
            >
              <div className="actionPopup">
                <Col
                  className="pb-2"
                  onClick={() =>
                    navigate('/manage-clinician', {
                      state: { selectedRow } as any,
                    })
                  }
                >
                  <Edit />
                  <label className="ps-3">Edit clinician</label>
                </Col>
                <Col className="pb-2" onClick={handleViewModalOpen}>
                  <VisibilityIcon style={{ fill: '#103558' }} />
                  <label className="ps-3">View</label>
                </Col>
                {selectedRow?.DNRCompanyIds?.length > 0 && (
                  <Col
                    className="pb-2"
                    onClick={() => {
                      setOpenClinicianDNRPopUp(true);
                      setAnchorEl(null);
                    }}
                  >
                    <DoNotDisturbOffIcon
                      style={{ height: '18px', width: '18px', fill: '#103558' }}
                    />
                    <label className="ps-3">Update DNR</label>
                  </Col>
                )}

                {selectedRow?.ProfileStatusId == 2 &&
                  selectedRow?.DNRCompanyIds?.length == 0 && (
                    <Col
                      className="pb-2"
                      onClick={() => {
                        setOpenClinicianDNRPopUp(true);
                        setAnchorEl(null);
                      }}
                    >
                      <DoNotDisturbOffIcon
                        style={{
                          height: '18px',
                          width: '18px',
                          fill: '#103558',
                        }}
                      />
                      <label className="ps-3">Mark as DNR</label>
                    </Col>
                  )}
                {selectedRow?.ProfileStatusId == 6 && (
                  <Col
                    className="pb-2"
                    onClick={() => {
                      setOpenClinicianUnterminatePopUp(true);
                      setAnchorEl(null);
                    }}
                  >
                    <IndeterminateCheckBoxIcon
                      style={{ height: '18px', width: '18px', fill: '#103558' }}
                    />
                    <label className="ps-3">Remove Termination</label>
                  </Col>
                )}
                {selectedRow?.ProfileStatusId == 2 && (
                  <Col
                    className="pb-2"
                    onClick={() => handleCheckClinicianShiftStatus("Terminate", selectedRow?.Id)}
                  >
                    <IndeterminateCheckBoxIcon
                      style={{ height: '18px', width: '18px', fill: '#103558' }}
                    />
                    <label className="ps-3">Terminate Clinician</label>
                  </Col>
                )}
                {selectedRow?.ProfileStatusId == 5 && (
                  <Col
                    className="pb-2"
                    onClick={() => {
                      setOpenClinicianUnSuspendPopUp(true);
                      setAnchorEl(null);
                    }}
                  >
                    <RemoveCircleTwoToneIcon
                      style={{ height: '18px', width: '18px', fill: '#103558' }}
                    />
                    <label className="ps-3">Remove Suspension</label>
                  </Col>
                )}

                {selectedRow?.ProfileStatusId == 2 && (
                  <Col
                    className="pb-2"
                    onClick={() => handleCheckClinicianShiftStatus("Suspend", selectedRow?.Id)}
                  >
                    <RemoveCircleTwoToneIcon
                      style={{ height: '18px', width: '18px', fill: '#103558' }}
                    />
                    <label className="ps-3">Suspend Clinician</label>
                  </Col>
                )}

                {(selectedRow?.ProfileStatusId === 4 ||
                  (selectedRow?.ProfileStatusId === 1 &&
                    selectedRow?.ProfileStatusId !=
                      ProfileStatusName.Pending)) && (
                  <Col onClick={() => handleStatus('unblock', selectedRow?.Id)}>
                    <Unblock />
                    <label className="ps-3">Unblock</label>
                  </Col>
                )}
              </div>
            </Popover>
          </div>
        );
      },
    },
  ];

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let offsetValue = page * pageSize;
    let query = {
      offset:
      offsetValue,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? '',
      companyId: tableOptions?.companyId ?? 0,
      skilIds: selectedSkill,
      profileStatusIds: selectedStatus
    };
    getCustomer(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  return (
    <Card className="p-6 customeboxShadow">
      <>
        <KTCard>
          <MuiTable
            addLabel="Add New Clinician"
            columns={columns}
            isAddEnable={false}
            data={appProviderListData?.Result ?? []}
            loading={isGettingThriveData}
            tableRef={tableRef}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            filters={
              <div className="d-sm-flex mb-4 align-items-end justify-content-between">
                <Box display="flex">
                  <div className="mb-sm-0 mb-4 position-relative d-flex">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Search Clinician"
                      aria-label="Search"
                      style={{
                        backgroundImage: `url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.80454 2.40976C7.78799 2.40985 6.78619 2.65303 5.88273 3.11903C4.97928 3.58503 4.20036 4.26032 3.61097 5.08857C3.02158 5.91683 2.6388 6.87402 2.49457 7.88029C2.35035 8.88657 2.44885 9.91274 2.78188 10.8732C3.1149 11.8337 3.67278 12.7006 4.40897 13.4016C5.14516 14.1026 6.03832 14.6173 7.01393 14.903C7.98953 15.1886 9.0193 15.2367 10.0173 15.0434C11.0153 14.8501 11.9526 14.421 12.751 13.7918L15.49 16.5308C15.6315 16.6674 15.8209 16.743 16.0176 16.7413C16.2142 16.7396 16.4024 16.6607 16.5414 16.5216C16.6805 16.3826 16.7593 16.1945 16.761 15.9978C16.7628 15.8012 16.6872 15.6117 16.5505 15.4703L13.8115 12.7313C14.5525 11.7912 15.0139 10.6616 15.1429 9.47155C15.2718 8.28155 15.0631 7.07928 14.5407 6.00233C14.0183 4.92538 13.2032 4.01728 12.1888 3.38193C11.1743 2.74659 10.0015 2.40968 8.80454 2.40976ZM3.92954 8.78476C3.92954 7.49183 4.44316 6.25185 5.3574 5.33761C6.27164 4.42337 7.51161 3.90976 8.80454 3.90976C10.0975 3.90976 11.3374 4.42337 12.2517 5.33761C13.1659 6.25185 13.6795 7.49183 13.6795 8.78476C13.6795 10.0777 13.1659 11.3177 12.2517 12.2319C11.3374 13.1461 10.0975 13.6598 8.80454 13.6598C7.51161 13.6598 6.27164 13.1461 5.3574 12.2319C4.44316 11.3177 3.92954 10.0777 3.92954 8.78476Z' fill='%23637281'/%3E%3C/svg%3E")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'left 8px center',
                        padding: '20px 30px 0px 30px',
                      }}
                      onChange={e =>
                        handleChangeFilters('search', e.target.value)
                      }
                      value={tableOptions?.search}
                    />
                    {tableOptions?.search && (
                      <i
                        className="fa fa-times"
                        aria-hidden="true"
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleChangeFilters('search', '')}
                      ></i>
                    )}

                    

                    {/* <select
                      disabled={isGettingskillData}
                      className="form-select form-select-solid form-select-lg"
                      onChange={e => {
                        setSelectedSkill(e.target.value);
                        handleChangeFilters('skilIds', e.target.value);
                      }}
                      style={{ marginLeft: '10px' }}
                    >
                      <option value="">Select skill</option>
                      {appSkillData
                        ? appSkillData?.Result?.map((skill: any) => (
                            <option value={skill?.Id} key={skill?.Id}>
                              {skill?.SkillName}
                            </option>
                          ))
                        : null}
                    </select>

                    <select
                      className="form-select form-select-solid form-select-lg stateSelect autofillField"
                      value={selectedStatus}
                      onChange={e => {
                        setSelectedStatus(e.target.value);
                        handleChangeFilters('profileStatusIds', e.target.value);
                      }}
                      style={{marginLeft:"10px"}}
                    >
                      <option value="">Select Status</option>
                      <option value="1">Pending</option>
                      <option value="2">Approved</option>
                      <option value="3">Rejected</option>
                      <option value="4">Blocked</option>
                      <option value="5">Suspended</option>
                      <option value="6">Terminated</option>
                    </select> */}
                  </div>
                  <Button
                      style={{ marginLeft: '10px' }}
                      onClick={() => setShowFilterPopUp(true)}
                    >
                      <FilterAltIcon />{' '}
                    </Button>
                </Box>
                {/* {!isSuperAdmin && (
                  <Grid item lg={3} md={4} sm={6} xs={12} className="ms-3">
                    <Box>
                      <label
                        className="form-label"
                        style={{
                          marginLeft: 5,
                          fontWeight: '900',
                          fontSize: '15.6px',
                        }}
                      >
                        Select Company
                      </label>
                      <select
                        className="form-select form-select-solid form-select-lg selectCompany"
                        onChange={(e: any) =>
                          handleChangeFilters('companyId', e?.target?.value)
                        }
                        value={tableOptions?.companyId}
                      >
                        <option value="">All</option>
                        {userInfo?.UserCompanies
                          ? userInfo?.UserCompanies?.map((company: any) => (
                              <option
                                value={company?.CompanyId}
                                key={company?.CompanyId}
                              >
                                {company?.CompanyName}
                              </option>
                            ))
                          : null}
                      </select>
                    </Box>
                  </Grid>
                )} */}
                <div className="d-flex justify-content-end">
                  <Link to="/manage-clinician" className="text-decoration-none">
                    <Button
                      className="d-flex align-items-center addClinician"
                      onClick={() => {
                        localStorage.setItem(
                          'previousPathnameKey',
                          window.location.pathname
                        );
                        dispatch(resetAllDocumentsValues());
                      }}
                    >
                      <KTIcon iconName="plus" className="fs-1" />
                      Add Clinician
                    </Button>
                  </Link>
                </div>
              </div>
            }
          />
        </KTCard>
        {showViewModal && (
          <ViewProvider
            viewData={selectedRow}
            show={showViewModal}
            providerId = {providerId}
            handleClose={handleModalClose}
          />
        )}

        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={() =>
              updateProviderStatusAPI(showStatusModel, showUserId)
            }
            loading={isUpdateProfile}
            title={'Update Status'}
            message={`Are you sure, do you want to ${showStatusModel} this Profile?`}
          />
        )}

        {openClinicianUnterminatePopUp && (
          <ConfirmEventPopup
            showPopup={openClinicianUnterminatePopUp}
            handleClose={handleCloseDelete}
            handleConfirmed={Unterminateclinician}
            loading={isUnterminate}
            title={'Remove Termination'}
            message={`Are you sure, do you want to remove termination ?`}
          />
        )}

        {openClinicianUnSuspendPopUp && (
          <ConfirmEventPopup
            showPopup={openClinicianUnSuspendPopUp}
            handleClose={handleCloseDelete}
            handleConfirmed={Unsuspendclinician}
            loading={isUnsuspend}
            title={'Remove Suspension'}
            message={`Are you sure, do you want to remove suspension ?`}
          />
        )}

        {openInProgressShiftPopUp && (
          <ConfirmEventPopup
            showPopup={openInProgressShiftPopUp}
            handleClose={handleCloseDelete}
            handleConfirmed={RedirectToManageShiftScreen}
            loading={isInprogressShiftloading}
            title={'In-Progress Shift Detected'}
            isFrom={"Clinician"}
            message={`The clinician currently has an in-progress shift. Before proceeding with this action, the shift must be completed.`}
          />
        )}

        {openClinicianDNRPopUp && (
          <UpdateClinicianStatusToDNR
            showPopup={openClinicianDNRPopUp}
            editData={selectedRow}
            handleClose={handleCloseUpdateClinicianStatusPopup}
          />
        )}

        {openClinicianTerminatePopUp && (
          <UpdateClinicianStatusToTerminatePopUp
            showPopup={openClinicianTerminatePopUp}
            editData={selectedRow}
            handleClose={closeTerminatePopup}
          />
        )}

        {openClinicianSuspendPopUp && (
          <UpdateClinicianStatusToSuspend
            showPopup={openClinicianSuspendPopUp}
            editData={selectedRow}
            handleClose={closeSuspendPopup}
          />
        )}

        {showFilterPopUp && (
          <FilterCommonPopUp
            showPopup={showFilterPopUp}
            values={{
              selectedSkill: selectedSkill,
              selectedStatus: selectedStatus,
            }}
            handleClose={handleFilterModalClose}
            page={'Clinician'}
          />
        )}

          <ShowClinicianHistoryInPopup
              id={HisotryId}
              open={openHisory as any}
              anchorEl={anchorElForHistory}
              onClose={handleCloseHistory}
              notes={selectedRow}
              title="Status History"
            />
      </>
    </Card>
  );
};

export default Clinician;

enum ProfileStatusName {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
  Blocked = 4,
  Suspended = 5,
  Terminated = 6,
}
