import React from 'react';
import { Popover, IconButton, Typography, Box } from '@mui/material';
import { convertDateToMMDDYYYY } from './commonFunctions';

const ShowClinicianHistoryInPopup = ({
  id,
  open,
  anchorEl,
  onClose,
  title,
  notes,
}: any) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiPaper-root': {
          width: '300px',
        },
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          top: '2px',
          right: '5px',
        }}
      >
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={onClose}
        >
          <span className="material-symbols-outlined closeBtn">cancel</span>
        </div>
      </IconButton>
      <div className="text-center p-4">
        <Typography variant="h6" gutterBottom className="mb-0">
          Status History
        </Typography>
      </div>
      <hr className="m-0" />
      <Box />
      <Box>
        {notes?.ProviderTerminationHistory?.length ? (
          <Box sx={{ padding: '12px 20px' }}>
            <Typography className="historyStatus" variant="h6">
              {' '}
              Status:{' '}
              <p
                style={{
                  color: '#F6851F',
                  display: 'inline-block',
                  marginBottom: '5px',
                }}
              >
                Terminated
              </p>{' '}
            </Typography>
            {notes?.ProviderTerminationHistory?.map(
              (historyItem: any, index: any) => (
                <Box
                  sx={{
                    backgroundColor: '#F3F3F3',
                    padding: '10px',
                    borderRadius: '5px',
                    marginBottom: '10px',
                    '&:last-child': {
                      margin: '0px',
                    },
                  }}
                >
                  <div>
                    <div key={index}>
                      <Typography
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                        }}
                      >
                        <span
                          style={{
                            display: 'inline-block',
                            margin: '0px',
                          }}
                        >
                          Date:{' '}
                        </span>
                        <span
                          style={{
                            display: 'inline-block',
                            fontWeight: '400',
                            margin: '0 0 0 3px',
                          }}
                        >
                          {convertDateToMMDDYYYY(historyItem?.TerminatedDate)}
                        </span>
                      </Typography>
                      <Typography
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                        }}
                      >
                        <span
                          style={{
                            display: 'inline-block',
                            margin: '0px',
                          }}
                        >
                          Reason:{' '}
                        </span>
                        <span
                          style={{
                            display: 'inline-block',
                            fontWeight: '400',
                            margin: '0 0 0 3px',
                          }}
                        >
                          {historyItem?.Remarks}
                        </span>
                      </Typography>
                    </div>
                  </div>
                </Box>
              )
            )}
          </Box>
        ) : null}
        <hr className="m-0" />
        {notes?.ProviderDNRHistory?.length ? (
          <Box sx={{ padding: '12px 20px' }}>
            <Typography
              variant="h6"
              style={{ fontSize: '14px', fontWeight: 'bold' }}
            >
              Status:{' '}
              <span
                style={{
                  color: '#107FA9',
                  display: 'inline-block',
                  marginBottom: '5px',
                }}
              >
                DNR
              </span>
            </Typography>
            {notes?.ProviderDNRHistory?.map((item: any, index: any) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: '#F3F3F3',
                  padding: '10px',
                  borderRadius: '5px',
                  marginBottom: '10px',
                  '&:last-child': {
                    margin: '0px',
                  },
                }}
              >
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      margin: '0px',
                    }}
                  >
                    Date:{' '}
                  </span>
                  <span
                    style={{
                      display: 'inline-block',
                      fontWeight: '400',
                      margin: '0 0 0 3px',
                    }}
                  >
                    {convertDateToMMDDYYYY(item?.DNRDate)}
                  </span>
                </Typography>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      margin: '0px',
                    }}
                  >
                    Facilities:{' '}
                  </span>
                  <span
                    style={{
                      display: 'inline-block',
                      fontWeight: '400',
                      margin: '0 0 0 3px',
                    }}
                  >
                    {item?.Companies}
                  </span>
                </Typography>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      margin: '0px',
                    }}
                  >
                    Reason:{' '}
                  </span>
                  <span
                    style={{
                      display: 'inline-block',
                      fontWeight: '400',
                      margin: '0 0 0 3px',
                    }}
                  >
                    {item?.Remarks}
                  </span>
                </Typography>
              </Box>
            ))}
          </Box>
        ) : null}
        <hr className="m-0" />
        {notes?.ProviderSuspensionHistory?.length ? (
          <Box sx={{ padding: '12px 20px', marginBottom: '10px' }}>
            <Typography
              variant="h6"
              style={{ fontSize: '14px', fontWeight: 'bold' }}
            >
              Status:{' '}
              <span
                style={{
                  color: '#FF5630',
                  display: 'inline-block',
                  marginBottom: '5px',
                }}
              >
                Suspended
              </span>
            </Typography>
            {notes?.ProviderSuspensionHistory?.map((item: any, index: any) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: '#F3F3F3',
                  padding: '10px',
                  borderRadius: '5px',
                  marginBottom: '10px',
                  '&:last-child': {
                    margin: '0px',
                  },
                }}
              >
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      margin: '0px',
                    }}
                  >
                    Date:{' '}
                  </span>
                  <span
                    style={{
                      display: 'inline-block',
                      fontWeight: '400',
                      margin: '0 0 0 3px',
                    }}
                  >
                    {convertDateToMMDDYYYY(item?.SuspendedDate)}
                  </span>
                </Typography>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      margin: '0px',
                    }}
                  >
                    Suspension period:{' '}
                  </span>
                  <span
                    style={{
                      display: 'inline-block',
                      fontWeight: '400',
                      margin: '0 0 0 3px',
                    }}
                  >
                    {item?.SuspensionDays} days
                  </span>
                </Typography>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      margin: '0px',
                    }}
                  >
                    Reason:{' '}
                  </span>
                  <span
                    style={{
                      display: 'inline-block',
                      fontWeight: '400',
                      margin: '0 0 0 3px',
                    }}
                  >
                    {item?.Remarks}
                  </span>
                </Typography>
              </Box>
            ))}
          </Box>
        ) : null}
      </Box>
    </Popover>
  );
};

export default ShowClinicianHistoryInPopup;
